import { createRouter, createWebHistory } from "vue-router";
import UserView from "../views/UserView.vue";
import LoginView from "../views/LoginView.vue";
import { authGuard, isSAdmin } from "./guards";

const routes = [
  {
    path: "/",
    name: "home",
    component: UserView,
    beforeEnter: authGuard,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/orglist",
    name: "orgs",
    beforeEnter: isSAdmin,
    component: () =>
      import(
        /* webpackChunkName: "OrganizationView" */ "../views/OrganizationView.vue"
      ),
  },
  {
    path: "/userlist",
    name: "users",
    beforeEnter: authGuard,
    component: () =>
      import(/* webpackChunkName: "UserView" */ "../views/UserView.vue"),
  },
  {
    path: "/permission-group",
    name: "permission-group",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "PermissionGroup" */ "../views/GroupView.vue"
      ),
  },
  {
    path: "/online",
    name: "online",
    beforeEnter: authGuard,
    component: () =>
      import(
        /* webpackChunkName: "PermissionGroup" */ "../views/UsersOnlineView.vue"
      ),
  },
  {
    path: "/user/:id",
    name: "user-detail",
    beforeEnter: authGuard,
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/UserDetailView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("../views/AccessView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
