<template>
  <h3 v-if="!userdata">Добавление сотрудника</h3>
  <!-- <form @submit.prevent="createUser"></form> -->
  <div class="container">
    <div class="fio">
      <BaseInput
        v-model="form.fio.$value"
        :has-error="form.fio.$hasError"
        :validating="loading || form.fio.$validating"
        :placeholder="loading ? 'Загрузка...' : 'Иванов Иван Иванович'"
        :disabled="loading || !isEnabledForm"
        required
        :id="form.fio.$uid"
        label="Ф.И.О"
      />
      <ValidationErrors class="validation-errros" :errors="form.fio.$errors" />
    </div>

    <div class="username">
      <BaseInput
        v-model="form.username.$value"
        :has-error="form.username.$hasError"
        :validating="loading || form.username.$validating"
        :placeholder="loading ? 'Загрузка...' : 'alex567'"
        :disabled="loading || !isEnabledForm"
        :id="form.username.$uid"
        label="логин (латиница)"
        required
      />
      <ValidationErrors
        class="validation-errros"
        :errors="form.username.$errors"
      />
    </div>

    <div class="email">
      <BaseInput
        v-model="form.email.$value"
        :has-error="form.email.$hasError"
        label="Email адрес"
        :validating="loading || form.email.$validating"
        :placeholder="loading ? 'Загрузка...' : 'ivanov@mail.ru'"
        :disabled="loading || !isEnabledForm"
        :id="form.email.$uid"
        required
      />
      <ValidationErrors :errors="form.email.$errors" />
    </div>

    <div class="password">
      <BaseInput
        v-model="form.password.$value"
        :has-error="form.password.$hasError"
        :validating="loading"
        v-if="isEnabledForm"
        type="password"
        :placeholder="loading ? 'Загрузка...' : '******'"
        :disabled="loading || !isEnabledForm"
        :required="!isEdit"
        :id="form.password.$uid"
        :label="isEdit ? 'Новый пароль' : 'Пароль'"
      />
      <ValidationErrors v-if="isEnabledForm" :errors="form.password.$errors" />
    </div>

    <div class="confirm-password">
      <BaseInput
        v-model="form.confirmPassword.$value"
        :has-error="form.confirmPassword.$hasError"
        :validating="loading"
        type="password"
        :required="!isEdit"
        v-if="isEnabledForm"
        :placeholder="loading ? 'Загрузка...' : '******'"
        :disabled="loading || !isEnabledForm"
        :id="form.confirmPassword.$uid"
        label="Повторите пароль"
      />
      <ValidationErrors
        v-if="isEnabledForm"
        :errors="form.confirmPassword.$errors"
      />
    </div>

    <div class="cellphone">
      <BaseInput
        v-model="form.phone.$value"
        :has-error="form.phone.$hasError"
        :validating="loading || form.phone.$validating"
        :placeholder="loading ? 'Загрузка...' : '375296785432'"
        :disabled="loading || !isEnabledForm"
        :id="form.phone.$uid"
        label="Телефон"
        required
      />
      <ValidationErrors :errors="form.phone.$errors" />
    </div>

    <div class="note">
      <BaseInput
        v-model="form.note.$value"
        :has-error="form.note.$hasError"
        type="text"
        :validating="loading || form.note.$validating"
        :placeholder="loading ? 'Загрузка...' : 'Любой текст'"
        :disabled="loading || !isEnabledForm"
        :id="form.note.$uid"
        label="Заметка"
      />
      <ValidationErrors class="validation-errros" :errors="form.note.$errors" />
    </div>

    <div v-if="!isEdit" v-role="'superadmin'" class="org">
      <BaseSelect
        v-model="form.org_id.$value"
        :has-error="form.org_id.$hasError"
        :validating="loading || form.org_id.$validating"
        :placeholder="
          loading || !this.$store.getters.getOrganizations
            ? 'Загрузка...'
            : null
        "
        :disabled="loading || !isEnabledForm || isEdit"
        type="text"
        :valueKey="'name'"
        :items="this.$store.getters.getOrganizations"
        label="Организация"
        v-model:changed="selectedOrgId"
        :id="form.org_id.$uid"
        required
      />
      <ValidationErrors
        class="validation-errros"
        :errors="form.org_id.$errors"
      />
    </div>
    <!-- :class="{ 'org': isSuperAdmin, 'role' : !isSuperAdmin  }" -->
    <div v-role="'superadmin'" class="role">
      <BaseSelect
        v-model="form.role_id.$value"
        :has-error="form.role_id.$hasError"
        :validating="loading || form.role_id.$validating"
        :placeholder="loading || !organizations ? 'Загрузка...' : null"
        :disabled="
          loading ||
          !isEnabledForm ||
          (isEdit && !this.$store.getters.isSuperAdmin)
        "
        type="text"
        :valueKey="'role'"
        :items="roles"
        label="Роль"
        :id="form.role_id.$uid"
        required
      />
      <ValidationErrors
        class="validation-errros"
        :errors="form.role_id.$errors"
      />
    </div>

    <div v-if="isEdit" class="org">
      <BaseSelect
        v-model="form.permission_group_id.$value"
        :has-error="form.permission_group_id.$hasError"
        :validating="loading || form.permission_group_id.$validating"
        :placeholder="
          loading || !organizations
            ? 'Загрузка...'
            : 'Без группы (полный доступ)'
        "
        :disabled="
          loading ||
          !isEnabledForm ||
          (isEdit && isCurrentUserAdmin && !this.$store.getters.isSuperAdmin)
        "
        type="text"
        :valueKey="'name'"
        :items="permissionGroups"
        label="Группа доступа"
        :id="form.permission_group_id.$uid"
      />
      <ValidationErrors
        class="validation-errros"
        :errors="form.permission_group_id.$errors"
      />
    </div>

    <div
      v-if="!isEdit"
      :class="{
        permission: this.$store.getters.isSuperAdmin,
        org: !this.$store.getters.isSuperAdmin,
      }"
    >
      <BaseSelect
        v-model="form.permission_group_id.$value"
        :has-error="form.permission_group_id.$hasError"
        :validating="loading || form.permission_group_id.$validating"
        :placeholder="
          loading || !organizations
            ? 'Загрузка...'
            : 'Без группы (полный доступ)'
        "
        :disabled="loading || !isEnabledForm"
        type="text"
        :valueKey="'name'"
        :items="permissionGroups"
        label="Группа доступа"
        :id="form.permission_group_id.$uid"
      />

      <ValidationErrors
        class="validation-errros"
        :errors="form.permission_group_id.$errors"
      />
    </div>

    <BaseButton
      class="submit-button"
      type="primary"
      @click="createUser"
      html-type="submit"
      v-if="!userdata && isEnabledForm"
      :disabled="submitting"
    >
      Создать
    </BaseButton>

    <div class="submit-button">
      <BaseButton
        type="primary"
        @click="updateUser"
        html-type="submit"
        v-if="userdata && isEnabledForm"
        :disabled="submitting"
      >
        Обновить
      </BaseButton>

      <BaseButton
        type="reset"
        @click="cancelSettings"
        html-type="submit"
        v-if="userdata && isEnabledForm"
        :disabled="submitting"
      >
        отмена
      </BaseButton>
    </div>
  </div>
  <!-- </FormProvider> -->
</template>

<script>
import { BaseApi } from "@/api/BaseApi";
import { toRefs, ref } from "vue";
import BaseInput from "@/components/UI/BaseInput.vue";
import BaseSelect from "@/components/UI/BaseSelect.vue";
import BaseButton from "@/components/UI/BaseButton.vue";
import ValidationErrors from "@/components/ValidationErrors";
import { useValidation, ValidationError } from "vue3-form-validation";
import {
  required,
  lettersAndNumbersOnly,
  min,
  minOrNull,
  passwordRegexOrNull,
  number,
  minMax,
  email,
  equal,
  passwordRegex,
} from "@/utils";
import { useToast } from "vue-toastification";
import store from "@/store";

export default {
  components: {
    BaseInput,
    BaseSelect,
    ValidationErrors,
    BaseButton,
    BaseButton,
  },
  setup(props, context) {
    const loading = ref(false);
    const toast = useToast();
    const { userdata } = toRefs(props);
    let isEdit = !!userdata.value;
    loading.value = isEdit;
    const checkUsername = (name) =>
      new Promise((resolve, reject) => {
        if (isEdit && userdata.value.username === name) {
          resolve();
          return;
        }
        BaseApi.checkUsername(name)
          .then(({ data: r }) => {
            r.data ? resolve() : reject("Такое логин уже занят");
          })
          .catch(() => reject("Ошибка проверки"));
      });
    const checkEmail = (email) =>
      new Promise((resolve, reject) => {
        if (isEdit && userdata.value.email === email) {
          resolve();
          return;
        }
        BaseApi.checkEmail(email)
          .then(({ data: r }) => {
            r.data ? resolve() : reject("Такой email есть в системе");
          })
          .catch(() => reject("Ошибка проверки"));
      });
    const passwdErrorMsg =
      "Пароль должнен содержать минимум одну заглавную букву, прописную, одну цифру и знак (@#\$&*~)";
    const passwdErrorLengMsg = "Длина пароля должна не менее 4 символов";
    const { form, errors, submitting, validateFields, resetFields, emit } =
      useValidation({
        username: {
          $value: "",
          $rules: [
            required("Логин обязателен для заполнения"),
            // lettersAndNumbersOnly("Введите логин латиницей"),
            min(4)("Длина логина должна быть не менее 4 символов"),
            checkUsername,
          ],
        },
        fio: {
          $value: "",
          $rules: [required("Имя обязательно для заполнения")],
        },
        email: {
          $value: "",
          $rules: [email("Введен невалидный эл.ящик"), checkEmail],
        },
        password: {
          $value: "",
          $rules: [
            isEdit
              ? minOrNull(4)(passwdErrorLengMsg)
              : min(4)(passwdErrorLengMsg),
            // isEdit
            //   ? passwordRegexOrNull(passwdErrorMsg)
            //   : passwordRegex(passwdErrorMsg),
            {
              key: "pw",
              rule: equal("Пароли не сходятся"),
            },
          ],
        },
        phone: {
          $value: "",
          $rules: [
            minMax(12, 12)("Телефон должен содержать 12 цифр"),
            number("Телефон должен содержать только цифры"),
          ],
        },
        role_id: {
          $value: store.getters.isSuperAdmin ? null : "",
          $rules: store.getters.isSuperAdmin ? [required("Выберите роль")] : [],
        },
        org_id: {
          $value: store.getters.isSuperAdmin ? null : "",
          $rules: store.getters.isSuperAdmin
            ? [required("Выберите организацию")]
            : [],
        },
        permission_group_id: {
          $value: null,
        },
        confirmPassword: {
          $value: "",
          $rules: [
            isEdit
              ? minOrNull(4)(passwdErrorLengMsg)
              : min(4)(passwdErrorLengMsg),
            // isEdit
            //   ? passwordRegexOrNull(passwdErrorMsg)
            //   : passwordRegex(passwdErrorMsg),
            {
              key: "pw",
              rule: equal("Пароли не сходятся"),
            },
          ],
        },
        note: {
          $value: "",
        },
      });
    if (isEdit) {
      setTimeout(() => {
        const { value } = userdata;
        value.access = null;
        console.log;
        resetFields({
          username: userdata.value?.username,
          fio: userdata.value?.fio,
          email: userdata.value?.email,
          password: userdata.value?.password,
          note: userdata.value?.note,
          permission_group_id: userdata.value?.permission_group_id,
          org_id: userdata.value?.org_id,
          role_id: userdata.value?.role_id,
          phone: userdata.value?.cellphone,
        });
        loading.value = false;
      }, 700);
    }

    const cancelSettings = () => {
      resetFields({
        username: userdata.value?.username,
        fio: userdata.value?.fio,
        email: userdata.value?.email,
        password: userdata.value?.password,
        note: userdata.value?.note,
        permission_group_id: userdata.value?.permission_group_id,
        org_id: userdata.value?.org_id,
        role_id: userdata.value?.role_id,
        phone: userdata.value?.cellphone,
      });
      context.emit("update:isEnabledForm", false);
      toast.success("Действия отменены");
    };

    const createUser = async () => {
      try {
        const formData = await validateFields();
        delete formData.confirmPassword;
        if (!store.getters.isSuperAdmin) {
          delete formData.org_id;
          delete formData.role_id;
        }
        store.dispatch("spinnerStatus", true);
        BaseApi.newUser(formData)
          .then(({ data: r }) => {
            context.emit("success");
            toast.success(r.message);
          })
          .finally(() => store.dispatch("spinnerStatus", false));
      } catch (e) {
        if (e instanceof ValidationError) {
          console.error(e.message);
        }
      }
    };

    const updateUser = async () => {
      try {
        const formData = await validateFields();
        const toast = useToast();
        delete formData.confirmPassword;
        const preparedForm = {
          ...formData,
          org_id: userdata.value.org_id,
          id: userdata.value.id,
          cellphone: formData.phone,
        };
        delete preparedForm.phone;
        delete preparedForm.confirmPassword;

        if (!store.getters.isSuperAdmin) {
          delete preparedForm.org_id;
          delete preparedForm.role_id;
        }
        store.dispatch("spinnerStatus", true);
        BaseApi.updateUser(preparedForm)
          .then(({ data: r }) => {
            context.emit("success");
            context.emit("update:isEnabledForm", false);
            toast.success(r.message);
          })
          .finally(() => store.dispatch("spinnerStatus", false));
      } catch (e) {
        console.error(e);
        if (e instanceof ValidationError) {
          console.error(e.message);
        }
      }
    };

    return {
      form,
      errors,
      submitting,
      resetFields,
      createUser,
      loading,
      isEdit,
      updateUser,
      cancelSettings,
    };
  },
  data() {
    return {
      organizations: [],
      roles: [],
      permissionGroups: [],
      selectedOrgId: null,
    };
  },
  props: {
    userdata: null,
    isEnabledForm: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    // if (this.$store.getters.isSuperAdmin) {
    //       BaseApi.orgList().then(({ data: r }) => {
    //   if (r.success) {
    //     this.organizations = r.data;
    //   }
    // });
    //}

    BaseApi.roleList(!this.userdata ? "add" : "edit").then(({ data: r }) => {
      if (r.success) {
        this.roles = r.data;
      }
    });
    if(this.userdata) {
      this.getPermissionGroupsByGroupId(this.userdata.org_id);
    }
  },
  methods: {
    getPermissionGroupsByGroupId(orgId) {
      BaseApi.getPermissionGroupsByGroupId(orgId).then(({ data: r }) => {
        if (r.success) {
          this.permissionGroups = r.data;
        }
      });
    },
  },
  watch: {
    selectedOrgId(v) {
      this.getPermissionGroupsByGroupId(v);
    },
  },
  computed: {
    isCurrentUserAdmin() {
      const role = this.roles.find((role) => role.id === this.userdata.role_id);
      return role && role.role === "admin";
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  background-color: #ffffff;
  margin-top: 25px;
  margin-bottom: 35px;
  min-width: 600px;
  max-width: 800px;
  width: 100%;
  display: grid;
  column-gap: 2rem;
  row-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "fio fio"
    "name email"
    "password confirm-password"
    "сellphone note"
    "org role"
    "permission permission"
    "buttons buttons";
}

.buttons {
  margin-top: 1.25rem;
  grid-area: buttons;
}

.fio {
  grid-area: fio;
}

.org {
  grid-area: org;
}

.role {
  grid-area: role;
}

.name {
  grid-area: name;
}

.сellphone {
  grid-area: сellphone;
}

.note {
  grid-area: note;
}

.email {
  grid-area: email;
}

.password {
  grid-area: password;
}

.confirm-password {
  grid-area: confirm-password;
}

.permission {
  grid-area: permission;
}

.submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
}
</style>
