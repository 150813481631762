<template>
  <label :for="autId" v-if="label" class="label">{{ label }}  <span class="required" v-if="required">*</span></label>
  <div class="_container" :class="attrsClassName">
    <select
      :disabled="disabled"
      :id="autId"
      :class="['_input', { '_input-error': hasError }]"
      v-bind="attrsRest"
      v-model="value"
    >
      <option class="no-option" :value="null">
        {{placeholder ? placeholder : 'Выберите'}}
      </option>

      <option
        v-for="option in items"
        :value="option.id"
        :key="option"
        :selected="option.id === value"
      >
        {{ option[valueKey] }}
      </option>
    </select>

    <!-- <input
        v-model="value"
        :id="autId"
        :class="['_input', { '_input-error': hasError }]"
        :disabled="disabled"
        :placeholder="placeholder"
        v-bind="attrsRest"
      /> -->
    <LoadingIcon
      class="_loading-icon"
      :class="{ '_loading-icon-error': hasError }"
      v-if="validating"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import LoadingIcon from "@/components/UI/LoadingIcon.vue";

export default defineComponent({
  inheritAttrs: false,
  components: { LoadingIcon },
  props: {
    modelValue: {
      type: [String, Number, Boolean, null],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validating: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    valueKey: {
      type: [String],
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: [String, Number, Boolean],
    },
    placeholder: {
      type: String,
    },
    hasError: {
      type: Boolean,
    },
    changed:{
      type: null
    }
  },
  emits: ["update:modelValue"],
  setup(props, { attrs }) {
    const { class: attrsClassName, id: autId, label, ...attrsRest } = attrs;
    return {
      attrsClassName,
      autId,
      attrsRest,
      label,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  emits: ['update:changed','update:modelValue'],
  watch: {
    value(e){
      this.$emit("update:changed", e);
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";

._container {
  position: relative;
}

._input {
  width: 100%;
  border: 1px solid $gray-400;
  outline: none;
  padding-left: 10px;
  border-radius: 0.25rem;
  
  &:focus {
    border-color: $green-400;
    box-shadow: 0 0 3px $green-400;
  }
  &:disabled {
    background-color: #e0d1d14d;
  }
}

.required {
  color: red;
}

._input-error {
  border-color: $red-400 !important;

  &:focus {
    box-shadow: 0 0 3px $red-400;
  }
}

._loading-icon {
  position: absolute;
  top: 50%;
  right: 0.75rem;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  color: $green-500;
}
._loading-icon-error {
  color: $red-400;
}
</style>
