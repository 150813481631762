<template>
  <b-row v-if="item" class="item" :class="[num % 2 ? 'white' : 'grey']">
    <b-col class="text">{{ item?.fio }}</b-col>
    <b-col class="text">{{ item?.email }}</b-col>
   <b-col v-role="'superadmin'" class="text">{{ item?.org?.name }}</b-col>
   <b-col class="text">{{ item?.role }}</b-col>
   <b-col cols="2" class="control">
      <b-button
      v-b-tooltip.hover
      title="Просмотр пользователя"
      @click="redirectToUserDetail"
      size="sm"
    >
    <svg-icon type="mdi" :path="mdiAccountCog" :size="20"></svg-icon>
    </b-button>


      <b-button
        v-b-tooltip.hover
        title="Удалить пользователя"
        size="sm"
        @click="deleteUser(item)"
        variant="danger"
      >
        <svg-icon type="mdi" :path="mdiAccountRemove" :size="20"></svg-icon>
      </b-button>
    </b-col> 
  </b-row>
  <confirm-dialog
    v-model:show="dialogDeleteUserVisible"
    @confirmed="confirmDeleting"
  >
  </confirm-dialog>
</template>

<script>
import ConfirmDialog from "@/components/UI/ConfirmDialog.vue";
import { mdiAccountCog } from "@mdi/js";
import { mdiAccountRemove } from "@mdi/js";
export default {
  name: "user-item",
  props: {
    item: null,
    num: null,
  },
  setup() {
    return {
      mdiAccountCog,
      mdiAccountRemove,
    };
  },
  data() {
    return {
      dialogDeleteUserVisible: false,
      deletingItem: null,
    };
  },
  emits: ['remove'],
  methods: {
    deleteUser(item) {
      this.dialogDeleteUserVisible = true;
      this.deletingItem = item;
    },
    confirmDeleting() {
      this.$emit("remove", this.deletingItem);
    },
    redirectToUserDetail() {
      this.$router.push({ name: 'user-detail', params: { id: this.item?.id } });
    },
  },
  components: {
    ConfirmDialog,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/style/constants.scss";
.item {
  min-height: 42px;
  @include display-align-justify(flex, center, space-between);
  padding: 0px 10px;
  margin: 0;
  flex-direction: row;
  &.white {
    background-color: #f0f1f2;
  }
  &.grey {
    background-color: #bbcad2;
  }
  .text {
    // font-weight: bold;
    font-size: 16px;
    color: #4c504e;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .control {
    display: flex;
    justify-content: flex-end;
    a {
      background-color: #4c504e;
      padding: 2px 9px;
      border-radius: 3px;
      color: white;
    }
   // margin-right: 20px;
    button {
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
</style>
