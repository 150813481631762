import store from "@/store";

class WebSocketSingleton {
  constructor() {
    this.websocket = null;
    this.connectionAttempts = 0;
    this.maxConnectionAttempts = 5;
    this.reconnectInterval = 5000;
    this.token = '';
  }

  initializeWebSocket(token, message = null) {
    this.token = token; // Устанавливаем токен
    const isLocal = window.origin.includes("http://localhost:8080");

    const url = `ws://${isLocal ? 'localhost': window.origin.includes('m2m') ? 'api.m2m.by' : '185.66.71.212'}:3031/api?token=${this.token}`;

    this.websocket = new WebSocket(url);
    this.websocket.addEventListener('open', (e) => {
      console.log('connected');
      setTimeout(()=>{
        this.send(JSON.stringify(message ?? {
          event: 'location',
          data: 'admin'
        }));
      }, 1000);
    });
    this.websocket.addEventListener('message', (event) => {
      if(event && event?.data) {
        const data = JSON.parse(event.data);
        if(data.code === 401) {
          this.connectionAttempts = this.maxConnectionAttempts
        }else{
          this.connectionAttempts = 0;
          store.dispatch('wsSetData',data);
        }
      }
    });
    this.websocket.addEventListener('close', () => {
      console.log('Соединение закрыто');
      if (this.connectionAttempts < this.maxConnectionAttempts) {
        this.connectionAttempts++;
        console.log(`Попытка переподключения #${this.connectionAttempts} через ${this.reconnectInterval / 1000} секунд`);
        setTimeout(() => {
          this.initializeWebSocket(this.token); // Повторная попытка подключения через интервал с учетом токена
        }, this.reconnectInterval);
      } else {
        console.log('Достигнуто максимальное количество попыток переподключения');
      }
    });
  }

  send(message) {
    if (!this.websocket || this.websocket.readyState !== WebSocket.OPEN) {
      console.log('Соединение не установлено');
      if(store.getters.getToken) {
        this.token = store.getters.getToken;
        this.initializeWebSocket(this.token, message);
      }
      return;
    }
    this.websocket.send(JSON.stringify(message));
  }

  disconnect() {
    if (this.websocket) {
      this.websocket.close();
      this.connectionAttempts = this.maxConnectionAttempts
      console.log('Соединение закрыто');
    }
  }

  start(token) {
    this.initializeWebSocket(token);
  }
}

export default new WebSocketSingleton();
