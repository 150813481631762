import { DefaultAPIInstance } from '@/api';

export const BaseApi = {

    userlist(org_id = null) {
        const url = "/user/list";
        const data = {
            currentPage: 1, 
            itemsPerPage: 10,
            org_id
        };
        return DefaultAPIInstance.post(url, data);
    },

    getAccessList(org_id = null) {
        const url = "/access/list";
        const data = {
            org_id
        };
        return DefaultAPIInstance.post(url, data);
    },

    saveAccessListByOrg(body) {
        const url = "/access/update";
        return DefaultAPIInstance.put(url,{
            org_id: body?.org_id,
            accepted_ids: body?.accepted_ids
        });
    },

    permissionGroupList() {
        const url = "/permissiongroup/list";
        return DefaultAPIInstance.get(url);
    },

    orgList() {
        const url = "/organization/list";
        return DefaultAPIInstance.get(url);
    },

    newPermissionGroup(data) {
        const url = "/permissiongroup/add";
        return DefaultAPIInstance.post(url,data);
    }, 

    newOrg(data) {
        const url = "/organization/new";
        return DefaultAPIInstance.post(url,data); 
    },

    updOrg(data) {
        const url = "/organization/update";
        return DefaultAPIInstance.put(url,data); 
    },

    deletePermissionGroup(id) {
        const url = "/permissiongroup/delete";
        return DefaultAPIInstance.post(url,{id: id});
    },

    subscriptionTypes() {
        const url = "/subscription/list";
        return DefaultAPIInstance.get(url);
    },

    updatePermissionGroup(data) {
        const url = "/permissiongroup/update";
        return DefaultAPIInstance.put(url,data);
    }, 

    getPermissionsByGroupId(id, org_id) {
        const url = "/permissions/byGroupId";
        return DefaultAPIInstance.post(url,{id: id, org_id});
    },

    savePermissionsByGroupId(body) {
        const url = "/permissions/byGroupId";
        return DefaultAPIInstance.put(url,body);
    },

    getPermissionGroupsByGroupId(orgId) {
        const url = "/permissiongroup/byOrgId";
        return DefaultAPIInstance.post(url,{org_id: orgId});
    },

    userById(id) {
        const url = "/user/detail";
        const data = {
            id: id
        };
        return DefaultAPIInstance.post(url, data);
    },

    orgList() {
        const url = "/organization/getAll";
        return DefaultAPIInstance.get(url);
    },

    roleList(type) {
        const url = "/roles?type=" + type;
        return DefaultAPIInstance.get(url);
    },

    newUser(data) {
        const url = "/user/registration";
        return DefaultAPIInstance.post(url,data);
    }, 

    updateUser(data) {
        const url = "/user/update";
        return DefaultAPIInstance.put(url,data);
    }, 

    checkUsername(login) {
        const url = "/user/username";
        return DefaultAPIInstance.post(url,{username: login});
    },

    checkEmail(mail) {
        const url = "/user/email";
        return DefaultAPIInstance.post(url,{email: mail});
    },

    deleteUser(id) {
        const url = "/user/delete";
        return DefaultAPIInstance.post(url,{id: id});
    }

}