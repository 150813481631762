import { createStore } from "vuex";
import router from "@/router/index";
import { AuthApi } from "@/api/AuthAPI";
import { BaseApi } from "@/api/BaseApi";
import { DefaultAPIInstance } from "@/api";
import VueJwtDecode from "vue-jwt-decode";
import webSocket from '@/api/ws';

const store = createStore({
  state: {
    ws: {
      data: {
        users_online: []
      }
    },
    credentials: {
      token: localStorage.getItem("m2token") || null,
      role:
        (localStorage.getItem("m2token") &&
          VueJwtDecode.decode(localStorage.getItem("m2token")).payload.role) ||
        null,
      userData: localStorage.getItem("m2data")
        ? JSON.parse(localStorage.getItem("m2data"))
        : null,
    },
    data: {
      organizations: localStorage.getItem("m2organizations")
      ? JSON.parse(localStorage.getItem("m2organizations"))
      : [],
    },
    app: {
      spinner: false,
      version: require("../../package.json").version,
    },
  },

  getters: {
    getToken: (state) => state.credentials.token,
    getWSdata: (state) => state.ws.data,
    spinnerStatus: (state) => state.app.spinner,
    getUserData: (state) => state.credentials.userdata,
    isSuperAdmin: (state) => state.credentials.role === "superadmin",
    getMyOrgId: (state) =>
      VueJwtDecode.decode(state.credentials.token).payload.org_id,
    getOrganizations: (state) => state.data.organizations,
  },

  mutations: {
    setSpinner(state, spinner) {
      state.app.spinner = spinner;
    },
    setToken(state, token) {
      state.credentials.token = token;
      localStorage.setItem("m2token", token);
      state.credentials.role = VueJwtDecode.decode(token).payload.role;
    },
    setUserData(state, userData) {
      state.credentials.userData = userData;
      localStorage.setItem("m2data", JSON.stringify(userData));
    },
    deleteToken(state) {
      state.credentials.token = null;
      localStorage.removeItem("m2token");
      state.credentials.role = null;
    },
    deleteUserData(state) {
      state.credentials.userData = null;
      localStorage.removeItem("m2data");
    },
    setOrganizations(state, data) {
      state.data.organizations = data;
      localStorage.setItem("m2organizations", JSON.stringify(data));
    },
    deleteOrganizations(state) {
      state.data.organizations = [];
      localStorage.removeItem("m2organizations");
    },

  },
  actions: {
    async onOrganizations({ commit, state }) {
        return BaseApi.orgList().then(({ data: r }) => {
          if (r.success) {
            commit("setOrganizations", r.data);
            return r.data;
          }
        });
    },
    async onLogin({ commit, state }, { username, password }) {
      commit("setSpinner",true);
      return AuthApi.login(username, password).then(({ data: r }) => {
        if (r.success) {
          commit("setToken", r.data.token);
          commit("setUserData", r.data);
          commit("setSpinner",false);
          DefaultAPIInstance.defaults.headers[
            "Authorization"
          ] = `Bearer ${r.data.token}`;
          BaseApi.orgList().then(({ data: r }) => {
            if (r.success) {
              commit("setOrganizations", r.data);
              return r.data;
            }
          });
        }
        return r;
      });
    },
    onLogout({ commit }) {
      delete DefaultAPIInstance.defaults.headers["Authorization"];
      commit("deleteToken");
      commit("deleteUserData");
      commit("deleteUserOrganizations");
      webSocket.disconnect();
      router.push({ name: "login" });
    },
    spinnerStatus({ commit }, status) {
      commit("setSpinner",status)
    },
    wsDisconnect({ commit, state}) {
      webSocket.disconnect()
     // console.log(el);
    },
    wsSetData({commit, state},{event, data}){
      state.ws.data[event] = data; 
    },
    wsSend({commit, state},value) {
      webSocket.send(JSON.stringify({
        event: value.event,
        data: value.data
      }));
      },
    wsConnect({ commit, state}) {
    webSocket.initializeWebSocket(state.credentials.token);
    }
  },
  modules: {}
});

//store.registerModule('authModule', AuthModule);

export default store;
