<template>
  <section id="header" class="header">
    <div class="container">
      <div class="row" :style="containerStyle">
        <div class="menuButton" v-b-toggle.main-menu v-if="$store.getters.getToken">
          <img src="../../assets/img/buttonmenu.svg" alt="" class="menu" />
          <!-- <span>меню</span> -->
        </div>
        <div class="col-md-4 logo">
          <img src="../../assets/img/LogoM2Admin.svg" alt="" />
        </div>
        <div class="searchButton col-md-4" v-if="$store.getters.getToken">
        </div>
      </div>
    </div>
  </section>

  <b-collapse id="main-menu">
  <section v-b-toggle.main-menu class="menu">
    <div v-role="'superadmin'" class="section icon">
      <router-link to="/orglist">
        <svg-icon type="mdi" class="icon" :path="mdiAccountGroup" :size="15"></svg-icon>
        <div>Организации</div>
      </router-link>
    </div>
    <div v-role="'superadmin'" class="section icon">
      <router-link to="/access">
        <svg-icon type="mdi" class="icon" :path="mdiAccountGroup" :size="15"></svg-icon>
        <div>Ограничение доступа</div>
      </router-link>
    </div>
    <div v-role="'superadmin'" class="section icon">
      <router-link to="/online">
        <svg-icon type="mdi" class="icon" :path="mdiAccountGroup" :size="15"></svg-icon>
        <div>Онлайн звери</div>
      </router-link>
    </div>
    <div class="section icon">
      <router-link to="/userlist">
        <svg-icon type="mdi" class="icon" :path="mdiAccountGroup" :size="15"></svg-icon>
        <div>сотрудники</div>
      </router-link>
    </div>
    <div class="section">
      <router-link to="/permission-group">
        <svg-icon type="mdi" class="icon" :path="mdiGroup" :size="15"></svg-icon>
        <div>Группы доступа</div>
      </router-link>
    </div>
    <div class="logOut">
      <div class="left">
        <div class="settings icon">
          <img src="../../assets/img/settingWhite.svg" alt="">
        </div>
        <div v-if="$store.state.credentials.userData" class="user">
          <p class="userName">{{$store.state.credentials.userData['fio']}}</p>
          <p class="last">
              <span>Последний вход:</span>
              <span class="date">
                {{toDate($store.state.credentials.userData['lastSignIn'])}}
            </span>
          </p>
        </div>
      </div>
      <div @click="$store.dispatch('onLogout')" class="right">
        <img src="../../assets/img/logout.svg">
      </div>
    </div>
  </section>
</b-collapse>
</template>

<script>
  import { mdiGroup, mdiAccountGroup } from '@mdi/js';
  
export default {
  name: "HeaderComponent",
  components: {},
  setup(){
    return {
      mdiGroup,
      mdiAccountGroup
    }
  },
  data() {
  return {

  }
},
methods: {
  toDate(date){
    return date ? (date.split("T")[0] + ' ' + date.split("T")[1].replace(".000Z","")) : 'первый вход';
  }
},
computed: {
  containerStyle() {
    return {
      'justify-content': this.$store.getters.getToken ? '' : 'center',
    }
  }
}
};
</script>

<style scoped lang="scss">
  @import "@/assets/style/constants.scss";
section.header {
  background-color: $color-blue;
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menuButton {
   // margin-left: 50px;
   max-width: 150px;
    img.menu {
      cursor: pointer;
      vertical-align: middle;
    }
    span {
      font-size: 22px;
      color: #ffffff;
      margin-left: 19px;
      vertical-align: middle;
    }
  }
  .logo {
    text-align: center;
  }
  .searchButton {
    position: relative;
    @include display-justify(flex, flex-end);
    padding-right: 50px;
    input {
      width: 255px;
      font-size: 18px;
      color: #b3cfd6;
      padding: 0 56px 0 17px;
    }
    img.searchButton {
      position: absolute;
      right: 2px;
      top: 3px;
      cursor: pointer;
    }
  }
}

section.menu {
  padding: 24px 0 0 0;
  background-color: #abb7bc;
  max-width: 30%;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
  position: absolute;
  z-index: 100;
  width: 300px;
  &.collapse.in.show {
    height: calc(100% - 134.98px) !important;
    display: flex !important;
  }
  a {
    text-decoration: none !important;
  }
  .section {
    cursor: pointer;
    width: 285px;
    padding: 0 0 0 26px;
    &:hover {
      background-color: #c3996b;
    }
    .icon {
      margin-right: 22px;
      width: 30px;
      height: 30px;
      color: white;
    }
    a {
      @include display-align(flex, center);
      flex-direction: row;
      color: #221e1f;
      font-size: 16px;
      line-height: 48px;
      img {
        margin-right: 26px;
        width: 30px;
        height: 30px;
      }
      div {
        text-transform: uppercase;
      }
    }
  }
  .nested {
    margin: 13px 0 14px 0;
    padding: 0 0 10px 0;
    background-color: #b5c0c4;
    width: 285px;
    cursor: default;
    .heading {
      padding: 0 0 0 30px;
      color: #ffffff;
      font-size: 19px;
      line-height: 63px;
      a {
        cursor: default;
      }
    }

  }
  .logOut {
    background-color: #b5c0c4;
    width: 285px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0 15px 0;
    padding: 9px 36px 8px 11px;
    .left {
      @include display-align(flex, center);
      .settings {
        padding-right: 14px;
        img {
          background: #abb7bc;
          cursor: pointer;
          width: 25px;
          height: 25px;
        }
      }
      .userName {
        color: #2e4a57;
        font-size: 12px;
        margin: 0;
      }
      .last {
        font-size: 9px;
        color: #95989c;
        margin: 0;
        .date {
          margin: 0 10px 0 5px;
        }
      }
    }
    .right {
      img {
        cursor: pointer;
        width: 32px;
        height: 32px;
      }
    }
  }
}

section.search {
  padding: 24px 0 0 0;
  background-color: #abb7bc;
  max-width: 40%;
  flex-direction: column;
  align-items: flex-end;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
  position: absolute;
  right: 0;
  z-index: 100;
  width: 100%;
  &.collapse.in.show {
    height: calc(100% - 134.98px) !important;
    display: flex !important;
  }
  .warningMessage {
    width: 90%;
    margin-right: 25px;
    margin-bottom: -10px;
  }
  .section {
    cursor: pointer;
    width: 100%;
    height: 106px;
    color: #fff;
    &.checkboxes {
      @include display-align(flex, center);
      padding-left: 10%;
      .check {
        margin-right: 22px;
        &:last-child {
          margin: 0;
        }
        /* The container */
        label {
          color: #fff;
          font-size: 16px;
          display: block;
          position: relative;
          padding-left: 35px;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          /* Hide the browser's default checkbox */
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 2px;
            left: 0;
            height: 21px;
            width: 21px;
            background-color: #abb7bc;
            border: 1px solid #fff;
          }
        }
        /* When the checkbox is checked, add a blue background */
        label input:checked ~ .checkmark {
          background-color: #fff;
        }
      }
    }
  }
  .section.light {
    background: #b5c0c4;
  }
  .section.blue {
    background: #abb7bc;
  }
  .section.blue,
  .section.light {
    @include display-align(flex, center);
    height: 48px;
    padding-left: 10%;
    color: #221e1f;
    font-size: 16px;
    &:hover {
      background: #c3996b;
      color: #fff;
    }
  }
}

.capital {
  text-transform: uppercase;
}
</style>
