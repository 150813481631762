<template>
  <container class="login">
  <form class="form" @submit.prevent="handleSubmit">
    <h3>Вход администратора</h3>
    <div class="container">
      <div class="name">
        <BaseInput
          v-model="form.username.$value"
          :has-error="submitted && form.username.$hasError"
          :validating="form.username.$validating"
          placeholder="логин / эл.ящик"
          :id="form.username.$uid"
        />
        <ValidationErrors
          class="validation-errors"
          :errors="submitted && form.username.$errors"
        />
      </div>

      <div class="password">
        <BaseInput
          v-model="form.password.$value"
          :has-error="submitted && form.password.$hasError"
          :validating="form.password.$validating"
          :type="'password'"
          placeholder="пароль"
          :id="form.password.$uid"
        />
        <ValidationErrors
          class="validation-errors"
          :errors="submitted && form.password.$errors"
        />
      </div>
    </div>

    <BaseButton
      class="submit-button"
      type="m2style"
      @click="submit()"
      html-type="submit"
      :disabled="submitting"
    >
      Войти
    </BaseButton>
  </form>
</container>
</template>
<script>
import BaseInput from "@/components/UI/BaseInput.vue";
import BaseButton from "@/components/UI/BaseButton.vue";
import ValidationErrors from "@/components/ValidationErrors";
import { useValidation, ValidationError } from "vue3-form-validation";
import router from "@/router/index";
import {
  required,
  min,
} from "@/utils";
import { useToast } from "vue-toastification";
import { useStore } from 'vuex';
export default {
  components: {
    BaseInput,
    ValidationErrors,
    BaseButton,
    BaseButton,
  },
  setup(props, context) {
    const toast = useToast();
    const store = useStore();
    const isLocal = window.origin.includes("http://localhost:8080");
    const { form, errors, submitting, validateFields, resetFields, emit } =
      useValidation({
        username: {
          $value: isLocal ? "sa@gmail.com" : "",
          $rules: [
            required("Логин обязателен для заполнения"),
          //  lettersAndNumbersOnly("Введите логин латиницей"),
            min(5)("Длина должна быть не менее 5 символов"),
          ],
        },
        password: {
          $value: isLocal ? "Argentum_951" : "",
          $rules: [
            min(3)("Длина пароля должна быть не менее 8 символов")
          ],
        },
      });

    const handleSubmit = async () => {
      try {
        const formData = await validateFields();
        store
        .dispatch("onLogin", formData)
        .then(() => {
          store.dispatch("wsConnect");
          router.push({ name: "users" });
        });
      } catch (e) {
        if (e instanceof ValidationError) {
          console.log(e.message);
          toast.error("Заполните поля формы корректно!");
        }
      }
    };

    return {
      form,
      errors,
      submitting,
      resetFields,
      handleSubmit,
    };
  },
  data() {
    return {
      submitted: false
    };
  },
  methods: {
    submit(){
      this.submitted = true;
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/style/constants.scss";
  .span {
    color: red;
  }

.login {
  display: flex;
  align-items: center;
 // background-color: rgb(238, 244, 246);
  .form {
    margin-top: calc($header-height / 4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;
    width: 100%;
    border: 1px $color-blue solid;
    background-color: white;
    border-radius: 10px;
    padding: 20px 10px;
  }

  .password {
    margin-top: 10px;
  }

  h3 {
    align-self: center;
  }
  
  .submit-button {
    margin-top: 15px;
    width: 100px;
    align-self: center;
  }
  .error-block {
    height: 15px;
  }
}

container {
  @include display-align-justify(flex, flex-start, space-between);
  flex-direction: column;
}

</style>
